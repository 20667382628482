export const folio = [
  {
    cover: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg',
    title: 'MAD CAP',
    subtitle: 'The coffee company',
  },
  {
    cover: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg',
    title: 'ADIDAS',
    subtitle: 'ALL DAY I DREAM ABOUT SPORT',
  },
  {
    cover: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg',
    title: 'LARQ',
    subtitle: 'BOTTLE REDESIGNED',
  },
  {
    cover: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg',
    title: 'NIKE',
    subtitle: 'Just do it',
  },
];

export const services = [
  {
    icon: 'fas fa-laptop-code',
    title: 'Web Design',
    description:
      'We design and develop beautiful, lightning fast, scalable, and marketing-focused websites tailored to grow your business.',
  },
  {
    icon: 'fas fa-mobile-alt',
    title: 'UI / UX Design',
    description:
      'We craft great user centered interfaces for web and mobile apps. We ensure to deliver intuitive and seamless experiences.',
  },
  {
    icon: 'far fa-lightbulb',
    title: 'Product Design',
    description:
      'We help you to carefully improve your digital product to deliver a great user experience, grow its user base and increase retention.',
  },
  {
    icon: 'fas fa-brain',
    title: 'Branding',
    description:
      'We transform businesses into brands through high quality brand identity design.',
  },
  {
    icon: 'fas fa-rocket',
    title: 'Optimization',
    description:
      'We help you optimize your website or mobile app to increase your conversion rates.',
  },
  {
    icon: 'fas fa-cogs',
    title: 'Automation',
    description:
      'We help your business gain leverage and efficiency through smart automation.',
  },
];

export const features = [
  'Affordable, scalable and performant. The perfect solution for small apps.',
  'A mid-sized solution for businesses undergoing rapid user growth.',
  "A farm of machines entirely dedicated to your company's storage needs.",
  'A mid-sized solution for businesses undergoing rapid user growth.',
];

export const gallery = [
  {
    cover: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg',
    title: 'MAD CAP',
    subtitle: 'The coffee company',
    cols: 1,
  },
  {
    cover: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg',
    title: 'ADIDAS',
    subtitle: 'ALL DAY I DREAM ABOUT SPORT',
    cols: 1,
  },
  {
    cover: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg',
    title: 'LARQ',
    subtitle: 'BOTTLE REDESIGNED',
    cols: 1,
  },
  {
    cover: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg',
    title: 'NIKE',
    subtitle: 'Just do it',
    cols: 1.5,
  },
  {
    cover: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg',
    title: 'MAD CAP',
    subtitle: 'The coffee company',
    cols: 1.5,
  },
  {
    cover: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg',
    title: 'ADIDAS',
    subtitle: 'ALL DAY I DREAM ABOUT SPORT',
    cols: 3,
  },
];
